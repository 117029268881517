import React, { useCallback, useMemo } from 'react';
import { Box, Switch, Theme, ThemeProvider, Typography, useMediaQuery } from '@material-ui/core';
import { useFormStyles } from 'modules/shared/components/formStyles';
import { FormikProps, useField } from 'formik';
import { generatePath } from "react-router";
import { useSelector } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import { selectTaskCreating } from 'store/modules/task/taskSelectors';
import { IChecklistItem } from 'api/task/taskModel';
import { TASK_DATE_FORMAT, TASK_MAX_TITLE_LENGTH } from 'modules/task/common/form/validation';
import { FormActions } from 'modules/shared/components/FormActions';
import { TaskChecklist } from 'modules/task/common/checklist/TaskChecklist';
import { TaskLocationsCreate } from 'modules/task/locations/TaskLocationsCreate';
import { AttachmentsCreate } from 'modules/task/attachments/Attachments';
import { RecurrenceDropdown } from '../common/recurrence/RecurrenceDropdown';
import { LabelsDropdown } from '../common/labels/LabelsDropdown';
import { WatchersDropdown } from '../common/watchers/WatchersDropdown';
import { TaskTaglist } from '../common/taglist/TaskTaglist';
import { DialogTemplate } from '../common/taskstemplate/DialogTemplate';
import { AttachmentsEdit } from '../attachments/Attachments/AttachmentsEdit';
import { Routes, browserHistory } from 'components/router/model';
import { TextInput } from 'components/basicInputs/textInput/TextInput';
import { DateInput } from 'components/basicInputs/dateInput/DateInput';
import { MarkdownInput } from 'components/basicInputs/MarkdownInput';
import { Breakpoints } from 'utils/styles/constants';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { TitleForm } from '../common/titleForm';
import { Switch as SwitchCustom } from 'components/switch/Switch';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { selectTaskTemplateFetching } from 'store/modules/task/taskTemplate/taskTemplateSelectors';
import { ContainerTasksTemplate } from '../common/taskstemplate/ContainerTasksTemplate';

type AddTaskFormProps = FormikProps<any> & {
    isEditTemplate?: boolean;
};

const checkistFieldName: string = 'subtasks';
const isExpiredDateFieldName: string = 'isExpirable';
const createdByFieldName: string = 'createdBy';
const isNewTemplateName: string = 'isNewTemplate';

export function AddTaskForm({ handleSubmit, isEditTemplate = false, ...props }: AddTaskFormProps) {
    const classes = useFormStyles();
    const [fieldChecklistItems] = useField(checkistFieldName);
    const [fieldIsExpiredDate] = useField(isExpiredDateFieldName);
    const [fieldcreatedBy] = useField(createdByFieldName);
    const [fieldIsNewTemplate] = useField(isNewTemplateName);
    const { value: valueCreatedBy } = fieldcreatedBy;
    const { value: valueIsExpirable, onChange } = fieldIsExpiredDate;
    const isPublishDateEnabled = useFeatureState(FeatureFlags.TaskCreatePublishDate);
    const isRecurrenceEnabled = useFeatureState(FeatureFlags.TaskCreateRecurrence);
    const isWatchersEnabled = useFeatureState(FeatureFlags.TaskWatchers);
    const isLabelEnabled = useFeatureState(FeatureFlags.TaskLabel);
    const isExpirableEnabled = useFeatureState(FeatureFlags.TaksExpirable);
    const isTagEnabled = useFeatureState(FeatureFlags.TaskTags);
    const isTasksTemplateEnabled = useFeatureState(FeatureFlags.TasksTemplate);
    const isNtfTaskDueReminderEnabled = useFeatureState(FeatureFlags.NtfTaskDueReminder);
    const { isOpened, onClose, onOpen } = useOpenedState();
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
        { noSsr: true },
    );
    const saveAsTemplateBtn = useMemo(() => {
        return isMobile ? <SaveIcon /> : <Trans>Save as New Template</Trans>;
    }, [isMobile])
    const isApproval = useMemo(() => (
        fieldChecklistItems.value.some((item: IChecklistItem) => item.requiresAttachment || item.requiresCustomResponse)
    ), [fieldChecklistItems])

    const taskCreating = useSelector(selectTaskCreating);
    const isTaskTemplateFetching = useSelector(selectTaskTemplateFetching);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { checked, name } } = event;
        onChange({
            target: {
                name: name,
                value: checked,
            }
        })
    }, [onChange])

    const handleSubmitNewTemplate = useCallback(() => {
        fieldIsNewTemplate.onChange({
            target: {
                name: isNewTemplateName,
                value: true,
            }
        });
        handleSubmit();
    }, [fieldIsNewTemplate, handleSubmit])

    const handleSubmitForm = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        fieldIsNewTemplate.onChange({
            target: {
                name: isNewTemplateName,
                value: false,
            }
        });
        if (isEditTemplate) {
            onOpen();
            return;
        }
        handleSubmit();
    }, [fieldIsNewTemplate, handleSubmit, isEditTemplate, onOpen]);

    const handleCancel = useCallback(() => {
        browserHistory.push(generatePath(Routes.TaskList));
    }, []);
    const title = isEditTemplate ? <Trans>Edit Task template</Trans> : <Trans>Create Task</Trans>;

    return (
        <form onSubmit={handleSubmitForm} data-testid="addTaskForm">
            <TitleForm
                title={title}
                showHourGlass={valueIsExpirable}
                tagList={isTagEnabled && <TaskTaglist nameField="tag" />}
                isApproval={isApproval}
            />
            {isLabelEnabled && (
                <Box className={classes.labelsDropdown}>
                    <LabelsDropdown
                        name="label"
                        label={`${t('Label')}`}
                    />
                </Box>)}
            <Box className={classes.formInputs}>
                <ContainerTasksTemplate isEditTemplate={isEditTemplate}/>
                <TextInput
                    classes={{ root: classes.titleInput }}
                    name="title"
                    label={`${t('Task Name')}`}
                    showCounter
                    maxLength={TASK_MAX_TITLE_LENGTH}
                />

                {isPublishDateEnabled && (
                    <Box className={classes.dateWrapper}>
                        <DateInput
                            name="publishDate"
                            label={`${t('Publish Date')}`}
                            valueFormat={TASK_DATE_FORMAT}
                            showTooltipLabel
                            tooltipTitle={`${t('Scheduled tasks will schedule based on the store(s) local time zone')}`}
                        />
                    </Box>
                )}

                {isRecurrenceEnabled && (
                    <Box className={classes.recurrenceWrapper}>
                        <RecurrenceDropdown
                            name="recurrence"
                            label={`${t('Recurrence')}`}
                            refDateName="publishDate"
                            showTooltipLabel
                            tooltipTitle={`${t('Scheduled tasks will schedule based on the store(s) local time zone')}`}
                        />
                    </Box>
                )}

                <Box className={classes.dateWrapper}>
                    <DateInput
                        name="dueDate"
                        label={`${t('Due Date')}`}
                        valueFormat={TASK_DATE_FORMAT}
                        minDate={new Date().toString()}
                        showTooltipLabel
                        tooltipTitle={`${t('Scheduled tasks will schedule based on the store(s) local time zone')}`}
                    />
                    {isExpirableEnabled && (<>
                        <Switch
                            checked={valueIsExpirable}
                            edge="start"
                            name="isExpirable"
                            color="primary"
                            onChange={handleChange}
                            data-test-id={"expire-add-task"}
                        />
                        <Typography variant="caption">
                            <Trans>Expire at Due Date</Trans>
                        </Typography>
                    </>)}
                </Box>
                <Box className={classes.recurrenceWrapper}>
                    {isWatchersEnabled && (
                        <WatchersDropdown
                            name="watchers"
                            label={`${t('Watchers')}`}
                            cretedBy={valueCreatedBy}
                        />
                    )}
                    {isNtfTaskDueReminderEnabled && (
                        <SwitchCustom
                            name="sendReminder"
                            label={t('Send Reminders')}
                            titleTooltip={<Trans>Sends Task Reminder Notification for Incomplete Tasks 12 & 6 Hours Before Due</Trans>}
                            bodyTooltip={<ContactSupportIcon color="inherit" fontSize="small" />}
                            showTooltip
                        />)}
                </Box>
                <MarkdownInput
                    name="description"
                    label={`${t('Description (optional)')}`}
                    classes={{ root: classes.descriptionInput }}
                />

                <ThemeProvider theme={{ MuiButton: {} }}>
                    {!isEditTemplate ?
                        <AttachmentsCreate />
                        : <AttachmentsEdit />
                    }

                    <TaskLocationsCreate fieldName='locations' fieldNameFromTemplate='locationsFromTemplate' />
                </ThemeProvider>

                <TaskChecklist fieldName={checkistFieldName} />
            </Box>

            <FormActions
                isInProgress={taskCreating || isTaskTemplateFetching}
                btnSaveText={isEditTemplate ? `${t('Save')}` : `${t('Create')}`}
                btnAnotherText={isEditTemplate || !isTasksTemplateEnabled ? '' : saveAsTemplateBtn}
                onClickBtnAnother={handleSubmitNewTemplate}
                onClickCancel={handleCancel} />
            <DialogTemplate
                open={isOpened}
                onCancel={onClose}
                onContinue={() => { onClose(); handleSubmit(); }}
                title={`${t('Are you sure you want to overwrite this template?')}`}
                hideBodyText
            />
        </form>
    );
}
