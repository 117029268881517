import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useLaunchCoverStyles } from 'modules/welcome/launchCover/styles';
import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { openLaunchCoverEditor } from 'store/modules/launch/launchActions';
import { selectLaunchCoverFetching } from 'store/modules/launch/launchSelectors';
import { useActions } from 'utils/store/useActions';
import { useButtonStyles } from 'utils/styles/buttons';

export const SaveImageLaunchCover = ({hasImage, onSaveImage}: {hasImage: boolean, onSaveImage: ()=> void}) => {
    const buttonClasses = useButtonStyles();
    const saveButtonClasses = clsx(buttonClasses.muiRoundedButton, 'primary');
    const isUploadingLaunchCoverImg = useSelector(selectLaunchCoverFetching);
    const actions = useActions({ openModal: openLaunchCoverEditor });
    const classes = useLaunchCoverStyles();

    const handleCancel = useCallback(() => {
        actions.current.openModal(false);
    }, [actions])
    

    return (
    <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="space-between" width="100%">
        <Box>
            <Typography
                variant="body2"
                style={{marginLeft: 24}}
            >
                <Trans>Recommended Aspect Ratio</Trans>: 10 x 1
            </Typography>
        </Box>
        <Box marginLeft="auto">
            <Box margin={3}>
                <Button
                    className={buttonClasses.muiRoundedButton}
                    onClick={handleCancel}
                    disabled={isUploadingLaunchCoverImg}
                ><Trans>Cancel</Trans></Button>
                {isUploadingLaunchCoverImg && (
                    <CircularProgress className={classes.circular} />
                )}
                {!isUploadingLaunchCoverImg && (
                    <Button
                        className={saveButtonClasses}
                        style={{ marginLeft: '15px' }}
                        disabled={!hasImage}
                        onClick={onSaveImage}
                    ><Trans>Save</Trans></Button>
                )}
            </Box>
        </Box>
    </Box>
    )
}
