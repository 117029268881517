import React from 'react';
import { Add as AddIcon } from '@material-ui/icons';
import {
    useMediaQuery,
    darken,
    styled,
    Box,
    Fab,
    Typography,
    Zoom,
    Theme,
    ButtonProps,
} from '@material-ui/core';
import { BlueColor, Breakpoints, UtilColors } from 'utils/styles/constants';

const StyledFab = styled(Fab)(({ theme }) => ({
    background: BlueColor.BluePrimaryMain,
    color: UtilColors.White,
    textDecoration: 'none',

    '&:hover': {
        background: darken(BlueColor.BluePrimaryMain, 0.05),
    },

    '& svg + *': {
        marginLeft: theme.spacing(1.5),
    },
}));

const StyledFabContainer = styled(Box)({
    bottom: `calc(env(safe-area-inset-bottom, 0px) + 30px)`, // ios-navbar fix
    right: 50,
});

export type CreateButtonProps = {
    className?: string;
    onClick?: ButtonProps['onClick'];
    isFixedPosition?: boolean;
    showChildren?: boolean;
};

export const CreateButton: React.FC<CreateButtonProps> = ({ children, onClick, className, isFixedPosition = true, showChildren = true }) => {
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
        { noSsr: true },
    );

    return (
        <StyledFabContainer className={className} style={isFixedPosition ? { position: 'fixed'} : {}}>
            <Zoom in timeout={500}>
                <StyledFab
                    variant="extended"
                    onClick={onClick}
                    data-test-id="create-button"
                >
                    <AddIcon />
                    {(!isMobile && showChildren) && (
                        <Typography>
                            {children}
                        </Typography>
                    )}
                </StyledFab>
            </Zoom>
        </StyledFabContainer>
    );
}
