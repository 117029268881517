import React from 'react';
import { Box, Dialog, Typography } from '@material-ui/core';
import 'react-image-crop/dist/ReactCrop.css';
import { useSelector } from 'react-redux';
import { selectAvatarEditorOpened } from '../../../../store/modules/user/userSelectors';
import { Trans } from 'react-i18next';
import { ImageEditor } from 'components/CropperImage/ImageEditor';
import { cropperImageStyles } from 'components/CropperImage/cropperImageStyles';
import { SaveImageCropedType } from 'components/CropperImage/saveImageCroped/SaveImageCropedMap';

export interface IUpdateLogoFormData {
    Avatar: string;
}

export function CropperModal() {
    const classes = cropperImageStyles();
    const isOpen = useSelector(selectAvatarEditorOpened);

    return (
        <Dialog
            open={isOpen}
            onClose={() => {}}
            fullScreen={false}
        >
            <Box className={classes.dialogBox}>
                <Box margin={'15px'}>
                    <Typography
                        variant="h3"
                        className={classes.pageTitle}
                    >
                        <Trans>Replace Avatar</Trans>
                    </Typography>
                </Box>
                <Box className={classes.boxInner1}>
                    <ImageEditor
                        typeSaveImageCroped={SaveImageCropedType.UserAvatar}
                        circularCrop
                        aspect={1}
                    />
                </Box>
            </Box>
        </Dialog>
    );
}
