import { IStoreState } from 'store/rootReducer';

export const selectLocationsFetching = (state: IStoreState) => state.hierarchy.locationsFetching;
export const selectLocationsByIds = (state: IStoreState) => state.hierarchy.locationsByIds;
export const selectLocationsByParentId = (state: IStoreState) => state.hierarchy.locationsByParentId;
export const selectLocations = (state: IStoreState) => state.hierarchy.locations;
export const selectHierarchy = (state: IStoreState) => state.hierarchy;
export const selectLocationsTree = (state: IStoreState) => state.hierarchy.locationsTree;
export const selectLocationsUserTreeById = (state: IStoreState) => state.hierarchy.locationsUserTreeById;
export const selectRelationsLocationsUserTreeById = (state: IStoreState) => state.hierarchy.relationsLocationsUserTreeById;
export const selectLocationsTreeFetching = (state: IStoreState) => state.hierarchy.locationsTreeFetching;
