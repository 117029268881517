import { useOpenedState } from 'utils/hooks/useOpenedState';
import { MoreVert } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { ButtonBase, Menu, MenuItem, Typography } from '@material-ui/core';
import { useActionButtonStyles } from 'components/button/actionButton/actionButtonStyles';
import { IAction } from 'components/button/actionButton/model';
import clsx from 'clsx';

interface IActionButtonClasses {
    moreIcon: string;
}

interface IActionButtonProps<TEntity> {
    width: number | 'auto';
    marginLeft?: number;
    items: Array<IAction<TEntity>>;
    entity: TEntity;
    disabled?: boolean;
    autoHide?: boolean;
    classes?: IActionButtonClasses;
}

export function ActionButton<TEntity>({
    width,
    items,
    entity,
    disabled,
    autoHide = false,
    marginLeft = 10,
    classes: customClasses
}: IActionButtonProps<TEntity>) {
    const classes = useActionButtonStyles({ width, marginLeft });
    const [buttonRef, setButtonRef] = useState<null | Element>(null);
    const { isOpened, onOpen, onClose } = useOpenedState();

    const openCallback = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        onOpen();
    }, [onOpen]);

    const closeCallback = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        onClose();
    }, [onClose]);

    return (
        <ButtonBase disabled={disabled}>
            <MoreVert
                className={clsx(classes.moreIcon, customClasses?.moreIcon)}
                ref={setButtonRef}
                onClick={openCallback}
                data-test-id={`action-button-grid`}
            />
            {buttonRef && (
                <Menu open={isOpened} 
                    onClose={closeCallback}
                    classes={{ paper: classes.menu }}
                    anchorEl={buttonRef}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {items.filter(a => a.isAvailable).map(({ name, Icon, action, key }: IAction<TEntity>) => (
                        <MenuItem key={`${name}-${key}`} className={classes.menuItem}
                            onClick={event => {
                                event.stopPropagation();
                                event.preventDefault();
                                action(entity);
                                if (autoHide) {
                                    onClose();
                                }
                            }}
                        >
                            <Icon fontSize="small" className={classes.icon}/>
                            <Typography variant="body2">{name}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </ButtonBase>
    );
}
