import { FC, useCallback } from "react";
import { SaveImageLaunchCover } from "./SaveImageLaunchCover";
import { SaveImageUserAvatar } from "./SaveImageUserAvatar";
import { useActions } from "utils/store/useActions";
import { launchCoverAddImage } from "store/modules/launch/launchActions";
import { requestUploadAvatar } from "api/files/filesActions";
import { PendingFile } from "store/modules/files/filesModel";
import { SaveImageChannelAvatar } from "./SaveImageChannelAvatar";

export enum SaveImageCropedType {
    LaunchCover = 'LAUNCH_COVER',
    UserAvatar = 'USER_AVATAR',
    ChannelAvatar = 'CHANNEL_AVATAR',
};

type SaveImageCropedCProps = {
    hasImage: boolean,
    onSaveImage: ()=> void
}

export type SaveImageCropedComponent = FC<SaveImageCropedCProps>;
export const SaveImageCropedComponents: Record<SaveImageCropedType, SaveImageCropedComponent> = {
    [SaveImageCropedType.LaunchCover]: SaveImageLaunchCover,
    [SaveImageCropedType.UserAvatar]: SaveImageUserAvatar,
    [SaveImageCropedType.ChannelAvatar]: SaveImageChannelAvatar
};


export const useSaveImageFile = (typeSaveImageCroped: SaveImageCropedType, onSaveImage?: (pendingFile: PendingFile) => void) => {
    const actions = useActions({ 
        addImage: launchCoverAddImage,
        userAvatar: requestUploadAvatar.init,
    });

    const callBackAction = useCallback((pendingFile: PendingFile) => {

        const actionMap = {
            [SaveImageCropedType.LaunchCover]: (file: PendingFile) => actions.current.addImage(file),
            [SaveImageCropedType.UserAvatar]: (file: PendingFile) => actions.current.userAvatar(file),
            [SaveImageCropedType.ChannelAvatar]: (file: PendingFile) => {
                onSaveImage?.(file)
            },
        };
        const executeAction = actionMap[typeSaveImageCroped];
        if (executeAction) {
            executeAction(pendingFile);
        } else {
            console.warn(`No action found for type: ${typeSaveImageCroped}`);
        }
    },
    [actions, typeSaveImageCroped, onSaveImage]);

    return { saveImageExecute: callBackAction };
};