import React, { useState, useRef, ReactNode, useCallback, useMemo } from 'react'
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useUploadStyles } from 'modules/shared/components/styles/uploadStyles';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import 'react-image-crop/dist/ReactCrop.css'
import { useDebounceEffect } from './useDebounceEffect';
import { PendingFile } from 'store/modules/files/filesModel';
import { SaveImageCropedComponents, SaveImageCropedType, useSaveImageFile } from './saveImageCroped/SaveImageCropedMap';

interface IImageUploadWithCropProps {
    initialCrop?: Crop;
    maxFileSizeMB?: number;
    textPreview?: ReactNode,
    acceptFile?: string | string[],
    isCenterBox?: boolean,
    typeSaveImageCroped: SaveImageCropedType,
    circularCrop?: boolean,
    aspect?: number,
    onSaveImage?: (pendingFile: PendingFile) => void
}
function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 100,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

export function ImageCropEditor({
    sourceImg,
    typeSaveImageCroped,
    scale = 1,
    rotate = 0,
    aspect = undefined,
    textPreview = <Trans>Avatar Preview</Trans>,
    circularCrop = false,
    onSaveImage = (pendingFile) => {}
}: {sourceImg: string | null, scale?: number, rotate?: 0, aspect?: number, textPreview?: ReactNode, typeSaveImageCroped: SaveImageCropedType, circularCrop?: boolean, onSaveImage?: (pendingFile: PendingFile) => void}) {
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const { saveImageExecute } = useSaveImageFile(typeSaveImageCroped, onSaveImage);

    const SaveImageCropedTypeShow = SaveImageCropedComponents[typeSaveImageCroped];

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
        else {
            setCrop({
                unit: '%',
                x: 0,
                y: 0,
                width: 50,
                height: 50,
            })
        }
    }

    const handleSaveImage = async () => {
        const image = imgRef.current
        const previewCanvas = previewCanvasRef.current
        if (!image || !previewCanvas || !completedCrop) {
            throw new Error('Crop canvas does not exist')
        }
    
        // This will size relative to the uploaded image
        // size. If you want to size according to what they
        // are looking at on screen, remove scaleX + scaleY
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
    
        const offscreen = new OffscreenCanvas(
          completedCrop.width * scaleX,
          completedCrop.height * scaleY,
        )
        const ctx = offscreen.getContext('2d')
        if (!ctx) {
            throw new Error('No 2d context')
        }
    
        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height,
        )
        // You might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size
        const blob = await offscreen.convertToBlob({
            type: 'image/jpeg',
            quality: 1
        })
        const file = new File([blob], 'launchCover.jpeg', {type: 'image/jpeg'});
        const pendingFile = {
            localFile: file,
            localKey: '',
            title: 'avatar.jpeg',
        } as PendingFile;

        saveImageExecute(pendingFile);
    }
  useDebounceEffect(
    async () => {
        if (
            completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current
        ) {
            // We use canvasPreview as it's much faster than imgPreview.
            canvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            completedCrop,
            scale,
            rotate,
            )
        }
    },
    100,
    [completedCrop, scale, rotate],
    )

    const canvasWHB = {
        width: circularCrop ? 100 : completedCrop?.width,
        height: circularCrop ? 100 : completedCrop?.height,
        borderRadius: circularCrop ? '50%': ''
    }
    return (
    <>
        <div style={{width: '100%',display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            {!!sourceImg && (
                <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
                minHeight={100}
                circularCrop={circularCrop}
                >
                    <img
                        ref={imgRef}
                        alt="Crop me"
                        src={sourceImg}
                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
            )}
        {(!!completedCrop) && (
            <>
                <Box margin={'4px'} mb={1} textAlign="center">{textPreview}</Box>
                <div>
                    <canvas
                    ref={previewCanvasRef}
                    style={{
                        border: '1px solid black',
                        objectFit: 'contain',
                        ...canvasWHB
                    }}
                    />
                </div>
            </>
        )}
        </div>
        <SaveImageCropedTypeShow onSaveImage={handleSaveImage} hasImage={!!sourceImg}/>
    </>
)
}

const bytesInMb = 1024 * 1024;

export function ImageEditor({
    maxFileSizeMB = 10,
    textPreview = <Trans>Avatar Preview</Trans>,
    acceptFile = 'image/*',
    isCenterBox = false,
    typeSaveImageCroped,
    circularCrop = false,
    aspect = undefined,
    onSaveImage = () => {}
}: IImageUploadWithCropProps) {
    const classes = useUploadStyles();
    const [sourceImg, setSourceImg] = useState<string | null>(null);
    const maxFileSize = maxFileSizeMB * bytesInMb;

    const onDrop = useCallback((uploadedFiles: File[]) => {
        uploadedFiles.forEach(file => {
            const reader = new FileReader();
            reader.addEventListener('load', () => setSourceImg(reader.result));
            reader.readAsDataURL(file);
        });
    }, [setSourceImg]);

    const {
        getRootProps,
        getInputProps,
        isDragReject,
        fileRejections,
        isFocused,
        isDragAccept,
    } = useDropzone({
        accept: acceptFile,
        onDrop,
        noDrag: false,
        multiple: false,
        maxSize: maxFileSize,
        noDragEventsBubbling: true,
    });

    const isFileTooLarge = fileRejections.length > 0 && fileRejections.some(file => file.file.size > maxFileSize);
    const hasError = isDragReject || isFileTooLarge;
    const errorText = (() => {
        if (isFileTooLarge) return t('File is too large.');
        if (isDragReject) return t('Please upload image file.');
    })();
    const classForDropzone = useMemo(() => {
        if (isDragAccept) {
            return classes.fileDropzoneOver;
        }
        if (isDragReject) {
            return classes.fileDropzoneError;
        }
        return classes.fileDropzone;
    }, [isDragAccept, isDragReject, classes]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems={ isCenterBox ? "center" : '' }
        >
            {!sourceImg && (
                <div {...getRootProps({isFocused, isDragAccept, isDragReject })} className={classForDropzone}>
                    <div className={classes.uploadHeader}>
                        <Publish fontSize="small" classes={{ root: classes.uploadIcon }}/>
                        <Typography
                            className={classes.uploadHeaderTitle}
                            color="primary"
                            variant="subtitle2"
                        >
                            <Trans>Upload Image or use Drag & Drop</Trans>
                        </Typography>
                    </div>
                    <input {...getInputProps() }/>
                    {hasError && (
                        <Typography
                            className={classes.uploadError}
                            color="primary"
                            variant="body1"
                        >
                            {errorText}
                        </Typography>
                    )}
                </div>
            )}
            <ImageCropEditor 
                sourceImg={sourceImg}
                textPreview={textPreview}
                typeSaveImageCroped={typeSaveImageCroped}
                circularCrop={circularCrop}
                aspect={aspect}
                onSaveImage={onSaveImage}
            />
        </Box>
    );
}
