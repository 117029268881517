import React, { ChangeEvent, useCallback } from 'react';
import {
    Box, Checkbox, CircularProgress, List, ListItem, makeStyles, Theme, Tooltip, Typography,
} from '@material-ui/core';
import {
    StoreTaskChecklistModel,
    StoreTaskFullModel,
} from 'api/task/storeTask/storeTaskModel';
import { useSelector } from 'react-redux';
import {
    selectChecklistItemsLoading,
    selectStoreTaskAttachmentsRemoving, selectStoreTaskAttachmentsUploading,
} from 'store/modules/task/storeTask/storeTaskSelectors';
import { useActions } from "utils/store/useActions";
import {
    requestChecklistItemSetCompleted,
} from 'api/task/storeTask/storeTaskActions';
import { Skeleton } from '@material-ui/lab';
import { StoreTaskChecklistFiles } from 'modules/task/singleTask/components/StoreTaskChecklistFiles';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { StoreTaskChecklistCustomResponse } from './StoreTaskChecklistCustomResponse';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { Trans } from 'react-i18next';
import { StoreTaskChecklistAvatarCompleted } from './StoreTaskChecklistAvatarCompleted';

interface IStoreTaskChecklistProps {
    task?: StoreTaskFullModel,
    isAutomationTask?: boolean
}

export const useStoreTaskChecklistStyles = makeStyles((theme: Theme) => ({
    listItem: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
    },
    checklistLoader: {
        padding: theme.spacing(1),
    },
}));

const loaderArray = new Array(3).fill(0);

export function StoreTaskChecklist({ task, isAutomationTask = false }: IStoreTaskChecklistProps) {
    const classes = useStoreTaskChecklistStyles([]);
    const { id: taskId } = (task || {});
    const isChangeDisabled = !!task?.isApproval && [
        DisplayedTaskStatus.Completed,
        DisplayedTaskStatus.Submitted,
        DisplayedTaskStatus.CompletedOverdue,
        DisplayedTaskStatus.SubmittedOverdue
    ].some(status => status === task?.displayedStatus);

    const actions = useActions({
        checklistItemCompleted: requestChecklistItemSetCompleted.init,
    });
    const checklistItemsLoading = useSelector(selectChecklistItemsLoading);
    const storeTaskAttachmentsRemoving = useSelector(selectStoreTaskAttachmentsRemoving);
    const storeTaskAttachmentsUploading = useSelector(selectStoreTaskAttachmentsUploading);
    const isCustomItemEnabled = useFeatureState(FeatureFlags.tasksCustomChecklistOptions);

    const onChecklistItemChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
        const { checked: completed, id: checklistItemId } = target;
        if (taskId) {
            actions.current.checklistItemCompleted({
                taskId, checklistItemId, completed,
            });
        }
    }, [actions, taskId]);

    const checklistItems = task?.checklistItems ? task.checklistItems.map(item => {
        return {
            ...item,
            attachments: item.attachments
                .filter(attachment => !storeTaskAttachmentsRemoving[attachment.fileId])
                .concat(storeTaskAttachmentsUploading[item.id] || []),
        };
    }) : [];

    const showCopyText = !!task?.isApproval && [
        DisplayedTaskStatus.Completed,
        DisplayedTaskStatus.CompletedOverdue,
        DisplayedTaskStatus.Submitted,
        DisplayedTaskStatus.SubmittedOverdue,
    ].some(status => status === task?.displayedStatus);

    return (
        <List>
            {task?.checklistItems ? checklistItems.map((checklistItem: StoreTaskChecklistModel) => {
                const checklistDisabled = (
                    !checklistItem.completed
                    && ( (checklistItem.attachments.length === 0 && checklistItem.requiresAttachment) 
                        || ( isCustomItemEnabled && !checklistItem?.customResponse && checklistItem.requiresCustomResponse) )
                );
                let titleTooltip = <></>;
                if (checklistItem.requiresAttachment && checklistDisabled) {
                    titleTooltip = <Trans>Upload attachments to proceed</Trans>;
                } else if (checklistItem.requiresCustomResponse && !checklistItem?.customResponse) {
                    titleTooltip = <Trans>Add the text in to the field to proceed</Trans>;
                }
                return (
                    <ListItem className={classes.listItem} key={checklistItem.id}
                        data-list-item="azaza">
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{zIndex: 0}}
                        >
                            <StoreTaskChecklistAvatarCompleted checklistItem={checklistItem} />
                            {checklistItemsLoading[checklistItem.id] ? (
                                <CircularProgress className={classes.checklistLoader} size={24}/>
                            ) : (
                                <Tooltip title={titleTooltip}>
                                    <span>
                                        <Checkbox
                                            id={checklistItem.id}
                                            color="primary"
                                            checked={checklistItem.completed}
                                            disabled={checklistDisabled || isChangeDisabled || isAutomationTask}
                                            onChange={onChecklistItemChange}
                                            data-test-id="checklist-checkbox"
                                        />
                                    </span>
                                </Tooltip>
                            )}
                            <Typography variant="body2">
                                {checklistItem.name}
                            </Typography>
                        </Box>
                        {checklistItem.requiresAttachment && 
                            <StoreTaskChecklistFiles
                                checklistItem={checklistItem}
                                taskId={taskId}
                                disabled={isChangeDisabled}
                            />}
                        { (checklistItem.requiresCustomResponse && isCustomItemEnabled) && 
                            <StoreTaskChecklistCustomResponse
                                checklistItem={checklistItem}
                                taskId={taskId}
                                disabled={isChangeDisabled}
                                showCopy={showCopyText}
                            />
                        }
                    </ListItem>
                );
            }) : loaderArray.map((item, index) => (
                <ListItem key={index}>
                    <Box className={classes.checklistLoader}>
                        <Skeleton width={24} height={32}/>
                    </Box>
                    <Skeleton width={180}/>
                </ListItem>
            ))}
        </List>
    );
}
