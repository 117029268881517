import React from 'react';
import { CreateButton } from 'modules/shared/components/CreateButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ListItemText, Menu, MenuItem, MenuProps } from '@material-ui/core';
import { BlueColor, UtilColors } from 'utils/styles/constants';
import { Routes, browserHistory } from 'components/router/model';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { t } from 'i18next';

const StyledMenu = withStyles({
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '& ul':{
      paddingTop: 0,
      paddingBottom: 0
    }

  },
})((props: MenuProps) => (
  <Menu
    elevation={3}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    borderRadius: 8,
    background: BlueColor.BluePrimaryMain,
    color: UtilColors.White,
    marginTop: 4,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.getContrastText(theme.palette.primary.dark),
    }
  },
}))(MenuItem);

const useStylesCreateEvent = makeStyles({
  createButton: {
    zIndex: 5
  },
});

export const CreateEventButton: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStylesCreateEvent();
    const isCalendarEventsEnabled = useFeatureState(FeatureFlags.CalendarEvents);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <>
        <CreateButton
            className={classes.createButton}
            onClick={handleClick}
            showChildren={false}
        />
        <StyledMenu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem onClick={() => browserHistory.push(Routes.AddTask)} data-test-id="create-task">
            <ListItemText primary={`${t('Task')}`} />
          </StyledMenuItem>
          {isCalendarEventsEnabled &&
            <StyledMenuItem onClick={() => browserHistory.push(Routes.AddCalendarEvent)} data-test-id="create-event">
              <ListItemText primary={`${t('Event')}`} />
            </StyledMenuItem>
          }
        </StyledMenu>
      </>        
    );
}
