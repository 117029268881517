import React, { useEffect, useMemo } from 'react';
import kebabCase from 'lodash/kebabCase';
import trimEnd from 'lodash/trimEnd';
import { Box } from '@material-ui/core';
import { createLegacyPage } from 'modules/legacyWrappers/createLegacyPage';
import { EmbeddedSubRoute, Routes } from 'components/router/model';
import { useAppPermissions } from 'store/modules/apps/appsHooks';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { selectFeatureFlags } from 'store/modules/featureFlags/selectors';
import { UtilColors } from 'utils/styles/constants';
import { useLegacySubRoutesStyles } from './shared/useLegacySubRoutesStyles';
import { isCurrentApp } from './shared/useIsCurrentApp';
import { EmbeddedSubRoutesTopMenu } from './EmbeddedSubroutesTopMenu';

interface IEmbeddedSubRoutesProps {
    path: string;
    apps: Array<EmbeddedSubRoute>;
}

function EmbeddedSubRoutes({ path, apps }: IEmbeddedSubRoutesProps) {
    const classes = useLegacySubRoutesStyles([]);
    const featureFlags = useSelector(selectFeatureFlags);
    const checkPermissions = useAppPermissions();
    const history = useHistory();
    const activeApps = apps
        .filter(app => app.appName ? checkPermissions(app.appName) : true)
        .filter(app => app.featureFlag ? featureFlags[app.featureFlag]?.enabled : true);

    const currentApp = activeApps.find(app => isCurrentApp(path, app, history));

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const RouteComponent = useMemo(() => {
        if (!currentApp) {
            return () => null;
        }

        return 'component' in currentApp
            ? currentApp.component
            : createLegacyPage(currentApp.url);
    }, [currentApp]);

    useEffect(() => {
        if (!activeApps.length) {
            history.replace(Routes.Forbidden);
        } else if (!currentApp) {
            if (history.location.pathname === path) {
                const defaultApp = activeApps[0];
                history.replace(`${path}/${kebabCase(defaultApp.name)}`);
            } else {
                const paths = activeApps.map(app => `${path}/${kebabCase(app.name)}`);
                const found = paths.find(item => item === trimEnd(history.location.pathname, '/'));
                if (found) {
                    history.replace(found);
                } else {
                    history.replace(Routes.NotFound);
                }
            }
        }
    }, [activeApps, currentApp, history, path]);

    if (!currentApp) {
        return null;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            bgcolor={UtilColors.Background}
        >
            <EmbeddedSubRoutesTopMenu
                apps={apps}
                path={path}
            />

            <Box className={classes.componentWrapper}>
                <RouteComponent />
            </Box>
        </Box>
    );
};

export function createEmbeddedSubRoutes(path: string, apps: Array<EmbeddedSubRoute>) {
    return function LegacySubRouteWrapper() {
        return (
            <EmbeddedSubRoutes
                apps={apps}
                path={path}
            />
        );
    };
}
