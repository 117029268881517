import React from 'react';
import { createBrowserHistory } from 'history';
import { AppName } from 'store/modules/apps/appsModel';
import { FeatureFlags } from 'utils/featureFlags';
import { SettingsPage } from 'modules/notifications/settings/SettingsPage';
import { UserListPage } from 'modules/users/user-list/UserListPage';
import { CompanyProfilePage } from 'modules/company-profile/CompanyProfilePage';
import { IntegrationsPage } from 'modules/integrations/IntegrationsPage';
import { SidekickPage } from 'modules/sidekick/SidekickPage';

export const browserHistory = createBrowserHistory();

export type TaskPageParams = {
    uuid: string;
}

export const UsersBetaAppName = 'Users';
export const UsersBetaAppPath = '/admin/users';

export enum Routes {
    Root = '/',
    Tasks = '/tasks',
    Learn = '/learn',
    Connect = '/connect/:cid?',
    Support = '/support',
    Library = '/library',
    TaskList = '/tasks/list/:uuid?',
    MyTasks = '/tasks/my-tasks',
    TaskListByLocation = '/tasks/tree',
    TaskListScheduled = '/tasks/scheduled',
    ScheduledTaskDetails = '/tasks/scheduled/:uuid',
    TaskListCalendar = '/tasks/calendar',
    AddCalendarEvent = '/tasks/calendar/add',
    EditCalendarEvent = '/tasks/calendar/edit/:prototypeId',
    AddTask = '/tasks/add',
    EditTask = '/tasks/edit/:prototypeId',
    ViewTask = '/tasks/:uuid',
    Profile = '/profile',
    SuperAdmin = '/super_admin',
    Analytics = '/analytics',
    Admin = '/admin',
    NotFound = '/error/pageNotFound',
    Forbidden = '/error/forbidden',
    Notifications = '/notifications',
    NotificationFeed = '/notification-feed',
    ProfileNew = '/users/profile', // NOTE: use regular profile route when legacy is deprecated
    AddUser = '/users/add',
    EditUser = '/users/:userId',
    EditUserLegacy = '/users/:userId/legacy',
    CompanyProfile = '/company-profile',
    Welcome = '/welcome',
    LearnCourse = '/learn/courses/:courseId?',
}

export enum LegacyRoutes {
    UsersNotifications = 'Users/Notifications',
    Library = 'Knowledge/Library',
    AnalyticsSubPath = 'app#/analytics',
    ContentLibrary = 'Learning/Content_Library',
    LearnCourse = 'learn/courses',
}

export const routeNames = {
    [Routes.Tasks]: 'Tasks',
    [Routes.Learn]: 'Learn',
    [Routes.Connect]: 'Connect',
    [Routes.Support]: 'Support',
    [Routes.Library]: 'Library',
    [Routes.Analytics]: 'Analytics',
    [Routes.Admin]: 'Admin',
    [Routes.SuperAdmin]: 'Super Admin',
    [Routes.Notifications]: 'Notifications',
    [Routes.NotificationFeed]: 'Notifications',
    [Routes.Welcome]: 'Welcome',
};

export interface IRouteConfiguration {
    legacy?: boolean;
    exact?: boolean;
    component: React.FC<any>;
    path: string;
    withMain?: boolean;
    appName?: AppName;
    featureFlag?: FeatureFlags;
}

export type EmbeddedSubRoute = {
    name: string;
    appName?: AppName;
    featureFlag?: FeatureFlags;
    childrenRoutes?: string[];
} & ({
    url: string;
} | {
    component: React.FC;
})

export const learnSubRoutes: Array<EmbeddedSubRoute> = [
    {
        name: 'Courses',
        url: 'Users/Dashboard',
        childrenRoutes: [Routes.LearnCourse]
    },
    {
        name: 'Designer',
        appName: AppName.LearningCourseDesigner,
        url: 'Learning/Course_Designer',
        childrenRoutes: []
    },
    {
        name: 'Content Library',
        appName: AppName.LearningContentLibrary,
        url: 'Learning/Content_Library',
        childrenRoutes: []
    },
];

export const analyticsSubRoutes: Array<EmbeddedSubRoute> = [
    {
        name: 'Overview',
        appName: AppName.AnalyticsOverview,
        url: 'app#/analytics/overview',
    },
    {
        name: 'Courses',
        appName: AppName.AnalyticsCoursesOverview,
        url: 'app#/analytics/courses',
    },
    {
        name: 'Users',
        appName: AppName.AnalyticsUsersOverview,
        url: 'app#/analytics/users',
    },
];

export const adminSubRoutes: Array<EmbeddedSubRoute> = [
    {
        name: UsersBetaAppName,
        appName: AppName.AdminUsers,
        featureFlag: FeatureFlags.Admin2_UserList,
        component: UserListPage,
    },
    {
        name: 'Notifications',
        url: 'notifications',
        component: SettingsPage,
    },
    {
        name: 'Roles',
        url: 'Admin/Roles',
        appName: AppName.AdminRoles,
    },
    {
        name: 'Locations',
        url: 'Admin/Locations',
        appName: AppName.AdminLocations,
    },
    {
        name: 'Admin Program',
        url: 'Admin/Programs',
        appName: AppName.AdminProgram,
    },
    {
        name: 'Company Profile',
        url: 'Admin/Company',
        appName: AppName.AdminCompanyProfile,
        component: CompanyProfilePage,
    },
    {
        name: 'API Clients',
        url: 'app#/admin/api-clients',
        appName: AppName.AdminAPIClient,
    },
    {
        name: 'Integrations',
        url: 'Admin/integrations',
        appName: AppName.Integrations,
        featureFlag: FeatureFlags.AISidekick,
        component: IntegrationsPage,
    },
    {
        name: 'Sidekick',
        url: 'Admin/sidekick',
        appName: AppName.Integrations,
        featureFlag: FeatureFlags.AISidekick,
        component: SidekickPage,
    },
];

export const superAdminSubRoutes: Array<EmbeddedSubRoute> = [
    {
        name: 'Clients',
        url: 'SuperAdmin/Companies',
        appName: AppName.SuperAdminClients,
    },
    {
        name: 'Apps',
        url: 'SuperAdmin/Apps',
        appName: AppName.SuperAdminApps,
    },

    {
        name: 'Data cleanup aid',
        url: 'SuperAdmin/CourseResultHelper',
        appName: AppName.SuperAdminData,
    },
];
