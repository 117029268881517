import localForage from 'localforage';
import { Tokens, TokenStorage } from 'api/auth/authModel';
import { isExpired } from 'utils/helpers/dateHelper';
import jwtDecode from 'jwt-decode';
import { getLanguage } from 'api/user/userModel';
import { CompanyColorType } from 'api/company/companyModel';

export enum LocalStorageKey {
    AccessToken = 'accessToken',
    RefreshToken = 'refreshToken',
    IdToken = 'idToken',
    AccessTokenSAdmin = 'accessTokenSAdmin',
    RefreshTokenSAdmin = 'refreshTokenSAdmin',
    Language = 'language',
    ThemeColor = 'themeColor'
}

export const storeTokens = async (tokens: Tokens) => {
    await Promise.all([
        localForage.setItem(LocalStorageKey.AccessToken, tokens.accessToken),
        localForage.setItem(LocalStorageKey.RefreshToken, tokens.refreshToken),
        localForage.setItem(LocalStorageKey.IdToken, tokens.idToken),
    ]);
};

export const storeTokensLoginAs = async (tokens: TokenStorage) => {
    await Promise.all([
        localForage.setItem(LocalStorageKey.AccessTokenSAdmin, tokens.accessToken),
        localForage.setItem(LocalStorageKey.RefreshTokenSAdmin, tokens.refreshToken),
    ]);
    window.location.reload();
};
export const storeLanguage = async (language: string) => {
    await Promise.all([
        localForage.setItem(LocalStorageKey.Language, language),
    ]);
};


export const eraseTokens = async () => {
    await Promise.all([
        localForage.removeItem(LocalStorageKey.AccessToken),
        localForage.removeItem(LocalStorageKey.RefreshToken),
        localForage.removeItem(LocalStorageKey.IdToken),
    ]);
}

export const eraseTokensSAdmin = async () => {
    await Promise.all([
        localForage.removeItem(LocalStorageKey.AccessTokenSAdmin),
        localForage.removeItem(LocalStorageKey.RefreshTokenSAdmin),
    ]);
}

/**
 * Returns token if it existst in localForage AND it's not expired,
 * otherwise - returns null
 * @param key localForage string key
 */
export async function tryGetLivingToken(key: LocalStorageKey): Promise<string | null> {
    const token = await localForage.getItem<string>(key);
    if (token) {
        try {
            const { exp } = jwtDecode<{ exp: number }>(token);
            return isExpired(exp) ? null : token;
        } catch {
            return null;
        }
    }
    return null;
}

export async function tryGetLivingTokens() {
    const accessToken = await tryGetLivingToken(LocalStorageKey.AccessToken);
    const refreshToken = await tryGetLivingToken(LocalStorageKey.RefreshToken);
    const accessTokenSAdmin = await tryGetLivingToken(LocalStorageKey.AccessTokenSAdmin);
    const refreshTokenSAdmin = await tryGetLivingToken(LocalStorageKey.RefreshTokenSAdmin);
    return { accessToken, refreshToken, accessTokenSAdmin, refreshTokenSAdmin };
}

export async function tryGetLivingLanguage(): Promise<string> {
    const language = await localForage.getItem<string>(LocalStorageKey.Language);
    if (language) {
        return language;
    }
    const browserLanguage = navigator.language.split('-')[0];
    return getLanguage(browserLanguage);
}

export async function eraseTokensReload() {
    await eraseTokens()
    await eraseTokensSAdmin();
    window.location.reload();
}

export const storeThemeColor = async (themeColor: CompanyColorType) => {
    await localForage.setItem(LocalStorageKey.ThemeColor, themeColor);
};
export async function tryGetThemeColor(): Promise<CompanyColorType | {}> {
    const themeColor = await localForage.getItem<CompanyColorType>(LocalStorageKey.ThemeColor);
    if (themeColor) {
        return themeColor;
    }
    return {};
}
