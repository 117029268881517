import { createSingleAction } from 'utils/store/actionUtils';
import { UserFormData } from '../../../modules/users/user-profile/model';
import { CheckedItems } from '../../../utils/hooks/useCheckdItems';
import { UserSearchRequest } from 'api/user/userModel';

export const REQUEST_USER_INFOS = 'REQUEST_USER_INFOS';

export const requestUserInfos = createSingleAction<
    string[], typeof REQUEST_USER_INFOS
>(REQUEST_USER_INFOS);

export const AVATAR_EXPLICIT_UPDATE = 'AVATAR_EXPLICIT_UPDATE';
export const avatarExplicitUpdateForUser = createSingleAction<
    boolean, typeof AVATAR_EXPLICIT_UPDATE>(AVATAR_EXPLICIT_UPDATE);

export const REQUEST_USER_REACTIVATION_CONFIRM = 'REQUEST_USER_REACTIVATION_CONFIRM';

export const requestConfirmUserReactivation = createSingleAction<
    UserFormData | null, typeof REQUEST_USER_REACTIVATION_CONFIRM>(REQUEST_USER_REACTIVATION_CONFIRM);

export const RESET_USER_LIST = 'RESET_USER_LIST';

export const resetUserList = createSingleAction<
    string[], typeof RESET_USER_LIST
>(RESET_USER_LIST);

export const GET_USER_LIST = 'GET_USER_LIST';

export const getUserList = createSingleAction<
    UserSearchRequest, typeof GET_USER_LIST
>(GET_USER_LIST);

export const OPEN_AVATAR_EDITOR = 'OPEN_AVATAR_EDITOR';
export const openAvatarEditor = createSingleAction<boolean, typeof OPEN_AVATAR_EDITOR>(OPEN_AVATAR_EDITOR);

export const OPEN_USER_IMPORT_MODAL = 'OPEN_USER_IMPORT_MODAL';
export const openUserImportModal = createSingleAction<boolean, typeof OPEN_USER_IMPORT_MODAL>(OPEN_USER_IMPORT_MODAL);

export enum BulkImportUsersSteps {
    Initial = 0,
    PostReport = 2,
}

export const SET_BULK_IMPORT_USERS_STEP = 'SET_BULK_IMPORT_USERS_STEP';

export const setBulkUsersImportStep = createSingleAction<
    BulkImportUsersSteps, typeof SET_BULK_IMPORT_USERS_STEP>(SET_BULK_IMPORT_USERS_STEP);

export const SET_USERS_CHECKED_ITEMS = 'USERS_SET_CHECKED_ITEMS';
export const setUsersCheckedItems = createSingleAction<
    CheckedItems, typeof SET_USERS_CHECKED_ITEMS>(SET_USERS_CHECKED_ITEMS);

export const SET_BULK_USER_STATUS_CHANGE_CONFIRM = 'SET_BULK_USER_STATUS_CHANGE_CONFIRM';
export const openUsersBulkStatusChangeConfirm = createSingleAction<
    string, typeof SET_BULK_USER_STATUS_CHANGE_CONFIRM>(SET_BULK_USER_STATUS_CHANGE_CONFIRM);

export type BulkUserUpdateActions =
    | ReturnType<typeof openUsersBulkStatusChangeConfirm>
    | ReturnType<typeof setBulkUsersImportStep>;

export type CheckerActions =
    | ReturnType<typeof setUsersCheckedItems>;

export type AvatarActionsType =
    | ReturnType<typeof openAvatarEditor>;

export type ImportActionsType =
    | ReturnType<typeof openUserImportModal>
    | ReturnType<typeof setBulkUsersImportStep>;

export type UserSingleIdActionType =
    | ReturnType<typeof requestConfirmUserReactivation>;

export type UserActionType =
    | ReturnType<typeof requestUserInfos>
    | ReturnType<typeof resetUserList>
    | ReturnType<typeof getUserList>;

export type AvatarExplicitUpdateActions =
    | ReturnType<typeof avatarExplicitUpdateForUser>;
