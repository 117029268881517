import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Fade, ListItem, SvgIconProps, Tooltip, Typography } from '@material-ui/core';
import { ILeftMenuItem } from 'modules/root/LeftMenu/LeftMenuItems';
import { useLeftMenuStyles } from 'modules/root/LeftMenu/LeftMenuStyles';
import { t as tI18n } from 'i18next';

export interface ILeftMenuItemProps {
    item: ILeftMenuItem;
    showTooltip: boolean
}
interface MenuTooltipProps {
    label: string,
    Icon: React.FC<SvgIconProps>,
}

export const MenuTooltip = ({ label, Icon }:MenuTooltipProps) =>
(
    <Box display="flex">
        <Tooltip title={label} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
            <Box>
                <Icon/>
            </Box>
        </Tooltip>
    </Box>
)

function renderIcon(
    Icon: React.FC<SvgIconProps>,
    Wrapper?: React.FC,
    text = '',
    showTooltip = false
) {
    if (Wrapper) {
        return (
            <Wrapper>
                <MenuTooltip label={showTooltip ? text : ''} Icon={Icon}/>
            </Wrapper>
        );
    }
    return <MenuTooltip label={showTooltip ? text : ''} Icon={Icon}/>;
}

export function LeftMenuItem({ item: { text, Icon, IconWrapper, Extra, to }, showTooltip }: ILeftMenuItemProps) {
    const classes = useLeftMenuStyles([]);
    return (
        <ListItem
            className={classes.navItem}
            component={NavLink}
            to={to}
            data-test-id={`menu-${text}`}
        >
            <Box mr={3}>
                {renderIcon(Icon, IconWrapper, tI18n(`menu.${text}`) ?? '', showTooltip)}
            </Box>
            <Typography>
                {tI18n(`menu.${text}`)}
            </Typography>
            {Extra && (
                <Extra/>
            )}
        </ListItem>
    );
}
