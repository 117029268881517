import { EmojiPicker } from "./EmojiPicker";
import { maxLengthCharacters, useCustomTextEditorStyles } from "../../utils/customTextEditor";
import { Box, Fade, Tooltip } from "@material-ui/core";
import { MessageInputControlButton } from "./MessageInputControls";
import { CustomPollSelector } from "./CustomPollSelector";
import { useDropzone } from "react-dropzone";
import { useMessageInputContext } from "stream-chat-react";
import { InputCounter } from "components/basicInputs/InputCounter";
import { t } from "i18next";
import { useFeatureState } from "utils/hooks/useFeatureState";
import { FeatureFlags } from "utils/featureFlags";

type CustomToolbarProps = {
    onSelectEmoji: (emoji: string) => void;
    currentLength: number
}

export const CustomToolbar: React.FC<CustomToolbarProps> = ({ onSelectEmoji, currentLength }) => {
  const isConnectFormatHyperlinkEnabled = useFeatureState(FeatureFlags.ConnectFormatHyperlink);
  const isConnectFormatBulletEnabled = useFeatureState(FeatureFlags.ConnectFormatBullet);
  const classes = useCustomTextEditorStyles([]);
  const {
      uploadNewFiles,
      maxFilesLeft,
      isUploadEnabled,
  } = useMessageInputContext();

  const {
      inputRef,
      getInputProps,
  } = useDropzone({
      disabled: !isUploadEnabled || maxFilesLeft === 0,
      noClick: true,
      onDrop: uploadNewFiles,
  });

  
  return(
    <div className="team-message-input__bottom">
      <Box display="flex" justifyContent="left" alignItems="center">
        <input {...getInputProps()} />
        <EmojiPicker onSelectEmoji={onSelectEmoji}/>
        <MessageInputControlButton
            type="fileUploadIcon"
            onClick={() => inputRef.current?.click()}
        />
        <CustomPollSelector />
        <div id="toolbar" className={classes.toolbar}>
          <TooltipToolbar title={t('Bold')}><button className="ql-bold" /></TooltipToolbar>
          <TooltipToolbar title={t('Italics')}><button className="ql-italic" /></TooltipToolbar>
          <TooltipToolbar title={t('Code')}><button className="ql-code" /></TooltipToolbar>
          { isConnectFormatHyperlinkEnabled && <TooltipToolbar title={t('Link')}><button className="ql-link" /></TooltipToolbar>}
          {isConnectFormatBulletEnabled && 
              <>
                <TooltipToolbar title={t('Numbering')}><button className="ql-list" value="ordered"/></TooltipToolbar>
                <TooltipToolbar title={t('Bullets')}><button className="ql-list" value="bullet"/></TooltipToolbar>
              </>
          }
        </div>
          <InputCounter
              className={classes.inputCounter}
              current={currentLength}
              max={maxLengthCharacters}
          />
        </Box>
      </div>
    )
};

type TooltipToolbarProps = {
  title: string,
  children: React.ReactElement
}
const TooltipToolbar: React.FC<TooltipToolbarProps> = ({ title, children }) => (
    <Tooltip title={title} arrow placement="top" TransitionComponent={Fade}>
        {children}
    </Tooltip>
)
