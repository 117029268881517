import { Box, Dialog, Typography } from '@material-ui/core';
import 'react-image-crop/dist/ReactCrop.css';
import { useSelector } from 'react-redux';
import { selectAvatarEditorOpened } from 'store/modules/user/userSelectors';
import { PendingFile } from 'store/modules/files/filesModel';
import { ImageEditor } from './ImageEditor';
import { cropperImageStyles } from './cropperImageStyles';
import { SaveImageCropedType } from './saveImageCroped/SaveImageCropedMap';

export interface IUpdateLogoFormData {
    Avatar: string;
}
export type CropperModalProps = {
    textPreview: string,
    title: string,
    onSaveImage: (pendingFile: PendingFile) => void
}

export function CropperModal({textPreview, title, onSaveImage}: CropperModalProps) {
    const classes = cropperImageStyles();
    const isOpen = useSelector(selectAvatarEditorOpened);

    return (
        <Dialog
            open={isOpen}
            onClose={() => {}}
            fullScreen={false}
        >
            <Box className={classes.dialogBox}>
                <Box margin={'15px'}>
                    <Typography
                        variant="h3"
                        className={classes.pageTitle}
                    >
                        {title}
                    </Typography>
                </Box>
                <Box>
                    <ImageEditor
                        textPreview={textPreview}
                        typeSaveImageCroped={SaveImageCropedType.ChannelAvatar}
                        circularCrop
                        aspect={1}
                        onSaveImage={onSaveImage}
                    />
                </Box>
            </Box>
        </Dialog>
    );
}
