import { Box, Button, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { useLaunchCoverStyles } from 'modules/welcome/launchCover/styles';
import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsAvatarUploading } from 'store/modules/files/filesSelectors';
import { openAvatarEditor } from 'store/modules/user/userActions';
import { selectUsersUpdatingById } from 'store/modules/user/userSelectors';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { useActions } from 'utils/store/useActions';
import { useButtonStyles } from 'utils/styles/buttons';

export const SaveImageUserAvatar = ({hasImage, onSaveImage}: {hasImage: boolean, onSaveImage: ()=> void}) => {

    const buttonClasses = useButtonStyles();
    const saveButtonClasses = clsx(buttonClasses.muiRoundedButton, 'primary');
    const user = useCurrentUser();
    const isUploadingAvatar = useSelector(selectIsAvatarUploading);
    const isUpdatingUser = useSelector(selectUsersUpdatingById)[user?.id || ''];
    const isInProgress = isUploadingAvatar || isUpdatingUser;
    const actions = useActions({ openModal: openAvatarEditor });
    const classes = useLaunchCoverStyles();

    const handleCancel = useCallback(() => {
        actions.current.openModal(false);
    }, [actions])
    

    return (
    <Box display={'flex'}>
        <Box marginLeft={'auto'}>
            <Box margin={'15px'}>
                <Button
                    className={buttonClasses.muiRoundedButton}
                    onClick={handleCancel}
                    disabled={isInProgress}
                >
                    <Trans>Cancel</Trans>
                </Button>
                {isInProgress && (
                    <CircularProgress className={classes.circular} />
                )}
                {!isInProgress && (
                    <Button
                        className={saveButtonClasses}
                        style={{ marginLeft: '15px' }}
                        disabled={isInProgress || !hasImage}
                        onClick={onSaveImage}
                    ><Trans>Save</Trans></Button>
                )}
            </Box>
        </Box>
    </Box>
    )
}