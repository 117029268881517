import { delay, put, takeLatest, select, takeEvery, call } from 'typed-redux-saga';
import { requestNotificationsCount } from 'api/legacy/legacyActions';
import {
    requestMultipleUsersInfo,
    requestSelfUserInfo, requestUserBulkStatusChange,
    requestUserCreate, requestUserLanguageUpdate, requestUserList,
    requestUserUpdate,
} from 'api/user/userActions';
import { requestStreamUserToken } from 'api/getStream/getStreamActions';
import { selectSelfId, selectUser } from '../auth/authSelectors';
import { requestIncompleteTasksCount, requestIncompleteUserTasksCount } from 'api/task/common/taskCommonActions';
import { requestUserRoles } from 'api/core/actions';
import {
    requestUserInfos,
    REQUEST_USER_INFOS,
    openAvatarEditor,
    avatarExplicitUpdateForUser,
    openUsersBulkStatusChangeConfirm, setUsersCheckedItems, resetUserList,
    GET_USER_LIST,
    getUserList
} from './userActions';
import {
    selectAvatarUploadingExplicit,
    selectUsersIds,
    selectUsersLoadingById
} from './userSelectors';
import difference from 'lodash/difference';
import { ToastVariant } from '../appState/appStateModel';
import { navigateTo, showToastMessage } from '../appState/appStateActions';
import { requestUploadAvatar } from '../../../api/files/filesActions';
import { UserDTO, getLanguage } from '../../../api/user/userModel';
import { Routes, UsersBetaAppPath } from '../../../components/router/model';
import { AppName } from '../apps/appsModel';
import { selectHasAppAccess } from '../apps/appsSelector';
import { getDefaultTaskFilter } from 'api/task/taskFilter/taskFilterActions';
import { selectFeatureFlags } from '../featureFlags/selectors';
import { FeatureFlagState } from '../featureFlags/model';
import { ItemsById } from 'model/entity';
import { storeLanguage } from 'modules/auth/storage';
import i18n from 'i18n';
import { FeatureFlags } from 'utils/featureFlags';
import { requestAppsGet } from 'api/apps/appsActions';
import { selectHasInitFilterFetching } from '../task/taskFilter/taskFilterSelectors';
import { userLocationTree } from 'modules/users/user-list/useUserLocationTree';
import { selectLocationsTreeFetching, selectLocationsUserTreeById } from '../hierarchy/hierarchySelectors';
import { Location as LocationUserTree } from 'api/location/locationModel';

const notificationsCheckIntervalMs = 1000 * 60;

function* userBulkStatusUpdateWatcher() {
    yield* takeLatest(
        requestUserBulkStatusChange.successType,
        function* ({ payload: userFilter }: ReturnType<typeof requestUserBulkStatusChange.success>) {
            yield put(setUsersCheckedItems({}));
            yield put(openUsersBulkStatusChangeConfirm(''));
            yield put(resetUserList([]));
            yield put(requestUserList.init(userFilter));
        },
    );
}

function* userInfoWatcher() {
    yield* takeLatest(
        requestSelfUserInfo.successType,
        function* () {
            yield put(requestStreamUserToken.init());
            yield put(requestUserRoles.init());
            yield put(requestIncompleteTasksCount.init());
            yield* put(requestIncompleteUserTasksCount.init());

            while (true) {
                const currentUserId = yield* select(selectSelfId);
                if (currentUserId) {
                    yield put(requestNotificationsCount.init());
                }
                yield delay(notificationsCheckIntervalMs);
            }
        },
    );
}
function* userInfoAppsWatcher() {
    yield* takeLatest(
        [
            requestSelfUserInfo.successType,
            requestAppsGet.successType
        ],
        function* () {
            const useCanAccessTasks: boolean = yield* select(selectHasAppAccess(AppName.Tasks));
            const featureFlags: ItemsById<FeatureFlagState> = yield* select(selectFeatureFlags);
            const isTasksFilterPersistenceEnabled = featureFlags[FeatureFlags.TasksFilterPersistence]?.enabled;
            const hasInitFilter = yield* select(selectHasInitFilterFetching);

            if(isTasksFilterPersistenceEnabled && !hasInitFilter){
                const currentUser: UserDTO | null | undefined = yield* select(selectUser);
                yield put(getDefaultTaskFilter.init({isStore: currentUser?.isCompanyLocationStore === true, useCanAccessTasks: useCanAccessTasks }));
            }
        }
    )
}

function* requestUserInfosWatcher() {
    yield* takeEvery(
        REQUEST_USER_INFOS,
        function* ({ payload: userIds }: ReturnType<typeof requestUserInfos>) {
            const loadedUserIds: Array<string> = yield* select(selectUsersIds);
            const loadingUserIds: ItemsById<boolean> = yield* select(selectUsersLoadingById);
            const missingUserIds = difference(userIds, loadedUserIds).filter(userId => !loadingUserIds[userId]);
            if (missingUserIds.length > 0) {
                yield* put(requestMultipleUsersInfo.init(missingUserIds));
            }
        },
    );
}

export function* userAvatarUpdatedWatcherSaga() {
    yield* takeEvery(
        requestUploadAvatar.successType,
        function* ({ type, payload }: ReturnType<typeof requestUploadAvatar.success>) {
            yield put(avatarExplicitUpdateForUser(true));
            yield put(openAvatarEditor(false));
            const user = (yield select(selectUser)) as UserDTO;
            yield put(requestUserUpdate.init({
                id: user.id,
                roleId: user.roleId,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: user.phoneNumber ?? undefined,
                employeeTitle: user.employeeTitle ?? undefined,
                companyLocationId: user.companyLocationId,
                isDeleted: user.isDeleted,
                avatarId: payload.fileId,
            }));
        },
    );
}

export function* userCreatedWatcherSaga() {
    yield takeEvery(
        [requestUserCreate.successType, requestUserUpdate.successType],
        function* ({ type, payload }: ReturnType<typeof requestUserCreate.success | typeof requestUserUpdate.success>) {
            const isAvatarUpdating = yield select(selectAvatarUploadingExplicit);
            if (isAvatarUpdating) {
                const message = 'User avatar Updated';
                yield put(avatarExplicitUpdateForUser(false));
                yield put(showToastMessage({
                    message,
                    options: {
                        variant: ToastVariant.Success
                    },
                }));
            } else {
                const message = type === requestUserCreate.successType
                    ? 'User Created'
                    : 'User Updated';

                const currentUserId = yield* select(selectSelfId);
                if(type === requestUserUpdate.successType && payload?.id === currentUserId){
                    const language = getLanguage(payload?.language);
                    i18n.changeLanguage(language);
                    yield put(navigateTo(Routes.Welcome));
                }else{
                    yield put(navigateTo(UsersBetaAppPath));
                }
                yield put(showToastMessage({
                    message,
                    options: {
                        variant: ToastVariant.Success
                    },
                }));
            }
        },
    );
}

export function* userlanguageUpdatedWatcherSaga() {
    yield* takeEvery(
        requestUserLanguageUpdate.successType,
        function* ({ payload: { language } }: ReturnType<typeof requestUserLanguageUpdate.success>) {
            i18n.changeLanguage(language);
            yield call(storeLanguage, language);
        },
    );
}

export function* getUserListWatcherSaga() {
    yield* takeEvery(
        GET_USER_LIST,
        function* ({ payload }: ReturnType<typeof getUserList>) {
            const locationsTreeFetching = yield* select(selectLocationsTreeFetching);
            if(locationsTreeFetching){
                yield delay(1000)
            }

            const locationTreeByUser: ItemsById<LocationUserTree> = yield* select(selectLocationsUserTreeById);
            const currentUser = yield* select(selectUser);

            const userlocationTree = yield* call(userLocationTree, locationTreeByUser, currentUser);

            if(!payload.fullName && !payload?.RoleIds?.length && (!payload?.UserState || payload?.UserState === "1" )&& !payload?.CompanyLocationIds?.length){
                payload.CompanyLocationIds = userlocationTree.map((loc)=>loc.id);
            }

            yield put(requestUserList.init(payload))
        },
    );
}

export const userSagas = [
    userInfoWatcher,
    requestUserInfosWatcher,
    userCreatedWatcherSaga,
    userAvatarUpdatedWatcherSaga,
    userBulkStatusUpdateWatcher,
    userlanguageUpdatedWatcherSaga,
    userInfoAppsWatcher,
    getUserListWatcherSaga
];
