import { IconButton, Tooltip } from '@material-ui/core';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { selectCalendarFilterTasksEvents } from 'store/modules/task/calendarFilter/calendarFilterSelectors';
import { calendarFilterTasksEvents } from 'store/modules/task/calendarFilter/calendarFilterTaskActions';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { useActions } from 'utils/store/useActions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useTaskFilterStyles } from '../../taskFilterStyles';
import { TasksEvents } from 'api/task/automationTask/automationTaskModel';
import ListAltIcon from '@material-ui/icons/ListAlt';
import EventIcon from '@material-ui/icons/Event';

export const CalendarTasksEventsFilter = () => {
  const isTasksCalendarViewEnableToggleEnabled = useFeatureState(FeatureFlags.TasksCalendarViewEnableToggle);
  const filterTasksEvents = useSelector(selectCalendarFilterTasksEvents);
  const actions = useActions({ calendarFilterTasksEvents });
  const classes = useTaskFilterStyles();
  const title = useMemo(()=> {
    switch (filterTasksEvents) {
      case TasksEvents.All:
        return `${t('Hide events')}`;
      case TasksEvents.Tasks:
        return `${t('Hide regular tasks')}`;
      case TasksEvents.Events:
        return `${t('Show regular tasks and events')}`;
    }
  }, [filterTasksEvents])

  const handleFilterScheduledTasks = useCallback(() => {
      actions.current.calendarFilterTasksEvents()
  },[actions]);

  const ToogleTypeShow = ToggleTasksEventsComponents[filterTasksEvents];

  if(!isTasksCalendarViewEnableToggleEnabled) return <></>;

  return (
    <Tooltip title={title}>
      <IconButton size='small' onClick={handleFilterScheduledTasks}>
        <VisibilityIcon color="primary" fontSize='medium'/>
        <ToogleTypeShow classes={classes.calendarScheduledTasksFilterIcon}/>
      </IconButton>
    </Tooltip>
  )
}

const AllTasksEvents: React.FC<ToggleTasksEventCProps> = () => <></>;
const OnlyTasks: React.FC<ToggleTasksEventCProps> = ({ classes }) => <ListAltIcon color="primary" className={classes}/>;
const OnlyEvents: React.FC<ToggleTasksEventCProps> = ({ classes }) => <EventIcon color="primary" className={classes}/>;

type ToggleTasksEventCProps = {
  classes: string;
}
type ToggleTasksEventsComponent = React.FC<ToggleTasksEventCProps>;
const ToggleTasksEventsComponents: Record<TasksEvents, ToggleTasksEventsComponent> = {
    [TasksEvents.All]: AllTasksEvents,
    [TasksEvents.Tasks]: OnlyTasks,
    [TasksEvents.Events]: OnlyEvents,
};
