import { useSelector } from 'react-redux';
import { nonEmpty } from 'utils/helpers/collections';
import { ItemsById } from 'model/entity';
import { selectLocationsUserTreeById } from 'store/modules/hierarchy/hierarchySelectors';
import { Location as LocationUserTree } from 'api/location/locationModel';
import { selectUser } from 'store/modules/auth/authSelectors';
import { UserDTO } from 'api/user/userModel';

export const userLocationTree = (locationTreeByUser: ItemsById<LocationUserTree>, currentUser: UserDTO| null | undefined) => {
    const keysLocationsTree = Object.keys(locationTreeByUser);
    if(!keysLocationsTree.length ) return [{
        id: currentUser?.companyLocationId ?? '',
        locationId: currentUser?.companyLocationId ?? '',
        name: currentUser?.companyLocationName ?? '',
        isStore: true,
        storesCount: 0,
        timeZoneId: '',
        icon: undefined
    }]

    return keysLocationsTree
    .map((loc: string) => {
        if( !locationTreeByUser[loc] ) return undefined;
        return {
            id: locationTreeByUser[loc]?.locationId ?? '',
            locationId: locationTreeByUser[loc]?.locationId ?? '',
            name: locationTreeByUser[loc]?.name ?? '',
            isStore: locationTreeByUser[loc]?.isStore ?? false,
            storesCount: locationTreeByUser[loc]?.storesCount ?? 0,
            timeZoneId: locationTreeByUser[loc]?.timeZoneId ?? '',
            icon: undefined
        }
    })
    .filter(nonEmpty)
}

export function useUserLocationTree() {
    const locationTreeByUser: ItemsById<LocationUserTree> = useSelector(selectLocationsUserTreeById);
    const currentUser = useSelector(selectUser);

    return userLocationTree(locationTreeByUser, currentUser);
}
