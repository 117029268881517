import { call, put, select, takeLatest } from 'typed-redux-saga';
import { requestSelfUserInfo } from 'api/user/userActions';
import { requestCompaniesInfo, requestCompanyColor, requestCompanyInfo, requestUpdateCompanyColor } from 'api/company/companyActions';
import { selectFeatureFlags } from '../featureFlags/selectors';
import { ItemsById } from 'model/entity';
import { FeatureFlagState } from '../featureFlags/model';
import { FeatureFlags } from 'utils/featureFlags';
import { storeThemeColor } from 'modules/auth/storage';

function* requestSelfUserWatcher() {
    yield* takeLatest(
        requestSelfUserInfo.successType,
        function* (action: ReturnType<typeof requestSelfUserInfo.success>) {
            const featureFlags: ItemsById<FeatureFlagState> = yield select(selectFeatureFlags);
            const isNewCompanyProfileEnabled = featureFlags[FeatureFlags.NewCompanyProfile]?.enabled;
            const { companyId } = action.payload;
            yield put(requestCompanyInfo.init(companyId));
            if(isNewCompanyProfileEnabled){
                yield put(requestCompanyColor.init(companyId));
            }

            if (action.payload.isSuperAdmin) {
                yield put(requestCompaniesInfo.init(null));
            }
        });
}

function* requestCompanyColorWatcher() {
    yield* takeLatest([
        requestCompanyColor.successType,
        requestUpdateCompanyColor.successType
    ],
        function* ({ payload }: ReturnType<typeof requestCompanyColor.success>) {
            yield call(storeThemeColor, payload);
        });
}

export const companySagas = [
    requestSelfUserWatcher,
    requestCompanyColorWatcher
];
