import { Location, LocationTree, LocationTreeResponse } from 'api/hierarchy/hierarchyModel';
import { HierarchyActions, requestCompanyLocationsUserTree, requestHierarchy } from 'api/hierarchy/hierarchyActions';
import { combineReducers } from 'redux';
import { ItemsById } from 'model/entity';
import { itemsByCustomIds, itemsByIds } from 'utils/helpers/itemsByIds';
import groupBy from 'lodash/groupBy';
import { Location as LocationUserTree } from 'api/location/locationModel';

export type HierarchyState = {
    locationsFetching: boolean;
    locationsByIds: ItemsById<Location>;
    locationsByParentId: ItemsById<Location[]>;
    locations: Location[];
    locationsTree: LocationTree[];
    relationsLocationsUserTreeById: Record<string, Array<string>>;
    locationsUserTreeById: ItemsById<LocationUserTree>;
    locationsTreeFetching: boolean
};

const initialValues: HierarchyState = {
    locationsFetching: false,
    locationsByIds: {},
    locationsByParentId: {},
    locations: [],
    locationsTree: [],
    locationsUserTreeById: {},
    relationsLocationsUserTreeById: {},
    locationsTreeFetching: false,
};

export const VoidParentLocationId = 'void';

export function locationsFetching(state = initialValues.locationsFetching, action: HierarchyActions): boolean {
    switch (action.type) {
        case requestHierarchy.initType:
            return true;
        case requestHierarchy.successType:
        case requestHierarchy.errorType:
            return false;
        default:
            return state;
    }
}

export function locationsByIds(
    state: ItemsById<Location> = initialValues.locationsByIds, action: HierarchyActions,
): ItemsById<Location> {
    switch (action.type) {
        case requestHierarchy.successType:
            return itemsByIds(action.payload);
        default:
            return state;
    }
}

export function locations(
    state: Location[] = initialValues.locations, action: HierarchyActions,
): Location[] {
    switch (action.type) {
        case requestHierarchy.successType:
            return action.payload;
        default:
            return state;
    }
}

export function locationsByParentId(
    state: ItemsById<Location[]> = initialValues.locationsByParentId, action: HierarchyActions,
): ItemsById<Location[]> {
    if (action.type === requestHierarchy.successType) {
        const grouped = groupBy(action.payload, (item) => item.parentLocationId ?? VoidParentLocationId);

        Object.values(grouped).forEach(locationItems => {
            locationItems.sort((a, b) => a.name.localeCompare(b.name));
        })

        return grouped;
    }

    return state;
}

function flattenTree(destArray: any, nodeList: any) {
    nodeList.forEach((node: any) => {
        destArray.push({...node, id: node.locationId});
        flattenTree(destArray, node.children || []);
    });
}

export function locationsTree(
    state: LocationTree[] = initialValues.locationsTree, action: HierarchyActions,
): LocationTree[] {
    if (action.type === requestCompanyLocationsUserTree.successType) {
        const { children } = action.payload;
        let locationsTreeList: LocationTree[] = [];
        flattenTree(locationsTreeList, children);

        return locationsTreeList;
    }

    return state;
}
export function relationsLocationsUserTreeById(
    state: Record<string, Array<string>> = initialValues.relationsLocationsUserTreeById, action: HierarchyActions,
): Record<string, Array<string>> {
    if (action.type === requestCompanyLocationsUserTree.successType) {
        const { children } = action.payload;
        let locationsTreeList: LocationTreeResponse[] = [];
        flattenTree(locationsTreeList, children);
        const relationLocationById: Record<string, Array<string>> =  {};

        for (const loc of locationsTreeList) {
            relationLocationById[loc.id] = loc.children.map(lChildren => (lChildren.locationId));
        }

        relationLocationById[action.payload.id] = action.payload.children.map(lChildren => (lChildren.locationId));
        return relationLocationById;
    }

    return state;
}

export function locationsUserTreeById(
    state: ItemsById<LocationUserTree> = initialValues.locationsUserTreeById, action: HierarchyActions,
): ItemsById<LocationUserTree> {
    if (action.type === requestCompanyLocationsUserTree.successType) {
        const { children } = action.payload;
        let locationsTreeList: LocationTreeResponse[] = [];
        flattenTree(locationsTreeList, children);

        const locationTree = locationsTreeList.map((loc) => ({
            isStore: loc.isStore,
            locationId: loc.locationId,
            name: loc.name,
            storesCount: loc.storesCount,
            children: loc.children.map((ch)=>(ch.locationId)),
            timeZoneId: loc.timeZoneId
        }))
        locationTree.push({
            isStore: action.payload.isStore,
            locationId: action.payload.locationId,
            name: action.payload.name,
            storesCount: action.payload.storesCount,
            children: action.payload.children.map((ch)=>(ch.locationId)),
            timeZoneId: action.payload.timeZoneId
        });

        return itemsByCustomIds(locationTree, 'locationId');
    }

    return state;
}

export function locationsTreeFetching(
    state: boolean = initialValues.locationsTreeFetching, action: HierarchyActions,
): boolean {
    switch (action.type) {
        case requestCompanyLocationsUserTree.initType:
            return true;
        case requestCompanyLocationsUserTree.successType:
        case requestCompanyLocationsUserTree.errorType:
            return false;
        default:
            return state;
    }
}

export const hierarchy = combineReducers({
    locations,
    locationsByIds,
    locationsByParentId,
    locationsFetching,
    locationsTree,
    locationsUserTreeById,
    relationsLocationsUserTreeById,
    locationsTreeFetching
});
